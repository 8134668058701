<template>
  <section id="praticien" class="flex flex-col gap-y-6">
    <div
      class="
        sm:flex
        lg:hidden
        xl:hidden
        md:flex
        flex-row
        items-center
        gap-x-4
        px-2
      "
    >
      <img src="/home/infirmier.svg" />
      <h1 class="text-2xl font-EffraM text-black">
        Infirmiers et aide-soignants
      </h1>
    </div>

    <div id="content">
      <div class="grid grid-cols-2 lg:grid-cols-3">
        <div class="hidden lg:block"></div>
        <div
          class="
            border border-t-4 border-r-0 border-b-0 border-solid
            b-free
            py-3
            px-1
            text-center
            font-EffraM
            text-lg
          "
        >
          Gratuit
        </div>
        <div
          class="
            border border-t-4 border-r border-b-0 border-solid
            b-premium
            py-3
            px-1
            text-center
            font-EffraM
            text-lg
          "
        >
          PREMIUM
        </div>
      </div>
      <div class="grid grid-cols-2 lg:grid-cols-3 h-200">
        <div
          class="
            border border-r-0 border-solid border-grayTable
            hidden
            lg:flex
            justify-center
          "
        >
          <div class="flex flex-row items-center gap-x-4 px-2">
            <h1 class="text-2xl font-EffraM text-black">
              Infirmiers et<br />
              aide-soignants
            </h1>
            <img src="/home/infirmier.svg" />
          </div>
        </div>
        <!----    Free pack ---->
        <div
          class="
            border border-r-0 border-solid border-grayTable
            flex flex-col
            py-6
          "
        >
          <div class="flex-1 flex justify-center">
            <h1 class="font-EffraM text-black text-3xl">0 MAD</h1>
          </div>
          <div class="flex justify-center">
            <button
              class="
                h-10
                bg-dokBlue-ultra
                text-white
                border-0
                py-2
                px-5
                focus:outline-none
                rounded
                font-EffraR
                cursor-pointer
                uppercase
                text-lg
              "
            >
              Choisir
            </button>
          </div>
        </div>
        <!----    349 DH pack ---->
        <div
          class="
            border border-r border-solid border-grayTable
            flex flex-col
            py-6
          "
        >
          <div class="flex-1 flex flex-col items-center justify-start px-3">
            <h1 class="font-EffraM text-black text-3xl">149 MAD</h1>
            <ul class="py-2">
              <li class="font-EffraR text-sm text-gray-700 leading-normal">
                (6 mois gratuits) A venir, en cours de développement
              </li>
            </ul>
          </div>
          <div class="flex justify-center">
            <button
              class="
                h-10
                bg-dokBlue-ultra
                text-white
                border-0
                py-2
                px-5
                focus:outline-none
                rounded
                font-EffraR
                cursor-pointer
                uppercase
                text-lg
              "
            >
              Choisir
            </button>
          </div>
        </div>
      </div>

      <!-- Content Tablet max width: 886px -->
      <div
        class="lg:hidden xl:hidden sm:flex md:flex flex-col"
        v-for="(item, index) in pricing"
        :key="`pharmacie-${index}`"
      >
        <div
          class="
            bg-sidebar
            flex flex-col
            py-4
            px-2
            border border-t-0 border-b-0 border-solid border-grayTable
          "
        >
          <div class="flex items-center gap-x-2 font-EffraM text-lg">
            {{ item.name }}
            <svg
              @click="item.tooltipIs = !item.tooltipIs"
              class="cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              fill="none"
              viewBox="0 0 16 17"
            >
              <g clip-path="url(#clip0)">
                <path
                  stroke="#AAA"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 15.167c3.682 0 6.666-2.985 6.666-6.666 0-3.682-2.984-6.667-6.666-6.667-3.682 0-6.667 2.985-6.667 6.667 0 3.681 2.985 6.666 6.667 6.666zM8 11.167V8.5M8 5.834h.007"
                />
              </g>
              <defs>
                <clipPath id="clip0">
                  <path
                    fill="#fff"
                    d="M0 0H16V16H0z"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div
            class="font-EffraR text-sm pb-2"
            style="color: #767676"
            v-show="item.tooltipIs"
          >
            {{ item.tooltip }}
          </div>
        </div>
        <div class="grid grid-cols-2 min-h-65">
          <div
            class="
              flex
              justify-center
              items-center
              border border-t-0 border-b-0 border-solid border-grayTable
            "
            :class="pricing.length - 1 === index ? 'border-b' : ''"
          >
            <svg
              v-if="item.free"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
              <path
                stroke="#1ED351"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M22.666 11L13.5 20.167 9.333 16"
              />
            </svg>
            <svg
              v-if="!item.free"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle
                cx="16.001"
                cy="16"
                r="16"
                fill="#FD5B68"
                fill-opacity=".2"
              />
              <path
                stroke="#FD5B68"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 11L11 21M11 11l10 10"
              />
            </svg>
          </div>
          <div
            class="
              flex
              justify-center
              items-center
              border
              border-t-0
              border-b-0
              border-l-0
              border-solid
              border-grayTable
            "
            :class="pricing.length - 1 === index ? 'border-b' : ''"
          >
            <svg
              v-if="item.premium"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
              <path
                stroke="#1ED351"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M22.666 11L13.5 20.167 9.333 16"
              />
            </svg>
            <svg
              v-if="!item.premium"
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              fill="none"
              viewBox="0 0 32 32"
            >
              <circle
                cx="16.001"
                cy="16"
                r="16"
                fill="#FD5B68"
                fill-opacity=".2"
              />
              <path
                stroke="#FD5B68"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M21 11L11 21M11 11l10 10"
              />
            </svg>
          </div>
        </div>
      </div>

      <!-- Content Desktop -->
      <div
        class="hidden lg:grid grid-cols-3 min-h-65 pricing-item"
        v-for="(item, index) in pricing"
        :key="`desk-pharmacie-${index}`"
      >
        <div
          class="
            px-3
            border-0 border-r border-l border-solid border-grayTable
            flex flex-col
            justify-center
          "
          :class="pricing.length - 1 === index ? 'border-b' : ''"
        >
          <div class="flex items-center gap-x-4 font-EffraM text-lg">
            {{ item.name }}
            <div>
              <svg
                @click="item.tooltipIs = !item.tooltipIs"
                class="cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                fill="none"
                viewBox="0 0 16 17"
              >
                <g clip-path="url(#clip0)">
                  <path
                    stroke="#AAA"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 15.167c3.682 0 6.666-2.985 6.666-6.666 0-3.682-2.984-6.667-6.666-6.667-3.682 0-6.667 2.985-6.667 6.667 0 3.681 2.985 6.666 6.667 6.666zM8 11.167V8.5M8 5.834h.007"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <path
                      fill="#fff"
                      d="M0 0H16V16H0z"
                      transform="translate(0 0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div
            class="font-EffraR text-sm pb-2"
            style="color: #767676"
            v-show="item.tooltipIs"
          >
            {{ item.tooltip }}
          </div>
        </div>
        <div
          class="
            flex
            items-center
            justify-center
            gap-x-4
            px-3
            font-EffraR
            text-base
            font-medium
            border-0 border-r border-l border-solid border-grayTable
          "
          :class="pricing.length - 1 === index ? 'border-b' : ''"
        >
          <svg
            v-if="item.free"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
            <path
              stroke="#1ED351"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M22.666 11L13.5 20.167 9.333 16"
            />
          </svg>
          <svg
            v-if="!item.free"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle
              cx="16.001"
              cy="16"
              r="16"
              fill="#FD5B68"
              fill-opacity=".2"
            />
            <path
              stroke="#FD5B68"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 11L11 21M11 11l10 10"
            />
          </svg>
        </div>
        <div
          class="
            flex
            items-center
            justify-center
            gap-x-4
            px-3
            font-EffraR
            text-base
            font-medium
            border-0 border-r border-l border-solid border-grayTable
          "
          :class="pricing.length - 1 === index ? 'border-b' : ''"
        >
          <svg
            v-if="item.premium"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle cx="16" cy="16" r="16" fill="#D8FFE3" />
            <path
              stroke="#1ED351"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M22.666 11L13.5 20.167 9.333 16"
            />
          </svg>
          <svg
            v-if="!item.premium"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            fill="none"
            viewBox="0 0 32 32"
          >
            <circle
              cx="16.001"
              cy="16"
              r="16"
              fill="#FD5B68"
              fill-opacity=".2"
            />
            <path
              stroke="#FD5B68"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M21 11L11 21M11 11l10 10"
            />
          </svg>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      pricing: [
        {
          name: "Publication et référencement",
          tooltipIs: false,
          tooltip:
            "Amélioration du positionnement et de la visibilité de vos profils professionnels dans les pages de résultats des moteurs de recherche ou d'annuaires.",
          free: true,
          premium: true,
        },
        {
          name: "Agenda connecté personnalisable",
          tooltipIs: false,
          tooltip:
            "Programmation de votre agenda digital selon vos convenances.  Les patients peuvent ainsi se renseigner sur vos disponibilités, les soins que vous prodiguez ainsi que vos tarifs. ",
          free: true,
          premium: true,
        },
        {
          name: "Licence pour un praticien",
          tooltipIs: false,
          tooltip:
            "Licence utilisateur unique donnant le droit à un seul praticien d'utiliser toutes les fonctionnalités offertes par la plateforme dok.ma pro. ",
          free: true,
          premium: true,
        },
        {
          name: "Gestion des rendez-vous en ligne",
          tooltipIs: false,
          tooltip:
            "Création, report ou annulation de vos rendez-vous en ligne. Le patient est informé instantanément des changements effectués.",
          free: false,
          premium: true,
        },
        {
          name: "Gestion de la patientèle",
          tooltipIs: false,
          tooltip:
            "Listage en ligne de tous les patients. Le praticien peut ainsi accéder à l'historique des soins prodigués à chaque patient.",
          free: false,
          premium: true,
        },
        {
          name: "Suivi des soins des patients",
          tooltipIs: false,
          tooltip:
            "Contrôle régulier des traitements et soins administrés aux malades chroniques, bléssés, brulés... etc.",
          free: false,
          premium: true,
        },
        {
          name: "Messagerie sécurisée",
          tooltipIs: false,
          tooltip:
            "Système de messagerie instantanée où seul le praticien peut initier une conversation avec son patient.",
          free: false,
          premium: true,
        },
        {
          name: "Stockage de données au Maroc",
          tooltipIs: false,
          tooltip:
            "Données personnelles protégées et non diffusées conformément à la loi marocaine. Données d'usage, totalement anonymisées, utilisées pour produire des statistiques sur l’utilisation des services du site, afin de permettre l’amélioration des services et des fonctionnalités de dok.ma.",
          free: true,
          premium: true,
        },
        {
          name: "Données sécurisées à l'aide de RBAC, TLS/SSL, Encryption at rest, CSFLE",
          tooltipIs: false,
          tooltip:
            "Accès aux données limité en fonction du rôle de l'utilisateur. Données cryptées pour être transportées sur une connexion réseau de confiance. Données cryptées sur le disque restent cryptées. Données sensibles cryptées à tout moment sur l'hôte de la base de données et en transit sur le réseau.",
          free: true,
          premium: true,
        },
        {
          name: "Pas de frais d'installation",
          tooltipIs: false,
          tooltip: "Installation gratuite et accompagnement offert.",
          free: true,
          premium: true,
        },
        {
          name: "Résiliation d'abonnement à tout moment",
          tooltipIs: false,
          tooltip: "Abonnement sans engagement, résiliable n'importe quand.",
          free: false,
          premium: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.b-free::v-deep {
  border-top-color: #c4c4c4;
  border-left-color: #ebebeb;
  border-right-color: #ebebeb;
  border-bottom-color: #ebebeb;
}

.b-premium::v-deep {
  border-top-color: #fd5b68;
  border-left-color: #ebebeb;
  border-right-color: #ebebeb;
  border-bottom-color: #ebebeb;
}
</style>
