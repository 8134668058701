import { render, staticRenderFns } from "./infirmier.vue?vue&type=template&id=14d87730&scoped=true&"
import script from "./infirmier.vue?vue&type=script&lang=js&"
export * from "./infirmier.vue?vue&type=script&lang=js&"
import style0 from "./infirmier.vue?vue&type=style&index=0&id=14d87730&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14d87730",
  null
  
)

export default component.exports